import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

const localeMessages = loadLocaleMessages()
const localeKeys = Object.keys(localeMessages)
const pathLocale = location.pathname.split('/')[1]
const navigatorLocale = localeKeys.indexOf(window.navigator.language.toLocaleLowerCase())
let localeKey = 'en'

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

if (localeKeys.indexOf(pathLocale) != -1) {
  localeKey = pathLocale
} else if (navigatorLocale != -1) {
  localeKey = localeKeys[navigatorLocale]
}

const dateTimeFormats: VueI18n.DateTimeFormats = Object.fromEntries(localeKeys.map((item) => {
  return [item, {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }]
}))
// TODO handle prerender call locale
// if (window['__PRERENDER_INJECTED__'] && window['__PRERENDER_INJECTED__'].lang) {
//   Vue.config.lang = window['__PRERENDER_INJECTED__'].lang
// } else {
//   Vue.config.lang = localeKey
// }

const html = document.documentElement // returns the html tag
html.setAttribute('lang', localeKey)

export default new VueI18n({
  locale: localeKey,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: localeMessages,
  dateTimeFormats
})
